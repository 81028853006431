import classNames from 'classnames';
import React, { FC } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { DefaultTooltip } from '../../atoms/tooltip/Tooltip';
import { Props as ElementCardProps } from '../../molecules/element-card/ElementCard';
import { Props as ElementCardButtonOnlyProps } from '../../molecules/element-card-button-only/ElementCardButtonOnly';
import Table from '../../molecules/table/Table';

export type Props = {
  elements: (
    | (ElementCardProps & { type: 'card' })
    | (ElementCardButtonOnlyProps & { type: 'button' })
  )[];
};

const ElementCardTable: FC<Props & WrappedComponentProps> = (props) => {
  const { elements, intl } = props;

  const renderColumns = [
    {
      key: 'headerCombined',
      renderHeader: () => '',
      renderCell: (headerCombined: {
        header: string;
        barColor: string;
        alerting: boolean;
        greyedOut: boolean;
      }) => (
        <div
          className={classNames(
            styles.headerCombined,
            { [styles.alerting]: headerCombined.alerting },
            { [styles.greyedOut]: headerCombined.greyedOut }
          )}
        >
          <div
            className={styles.colorBar}
            style={{ backgroundColor: headerCombined.barColor }}
          />
          <span className={styles.header}>{headerCombined.header}</span>
        </div>
      ),
    },
    {
      key: 'subHeader',
      renderHeader: () => '',
      renderCell: (subHeader: string) => (
        <span className={styles.subHeader}>{subHeader}</span>
      ),
    },
    {
      key: 'content',
      renderHeader: () => '',
      renderCell: (content: {
        info: Record<string, string>;
        error: string;
      }) => {
        if (content.error) {
          return (
            <div className={styles.error}>
              <LinesEllipsis
                text={content.error}
                maxLine={1}
                ellipsis={'...'}
                trimRight
                basedOn={'letters'}
              />
            </div>
          );
        }

        const infoString = Object.entries(content.info || {})
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');

        return (
          <div className={styles.info} title={infoString}>
            {Object.entries(content.info || {})
              .slice(0, 3)
              .map(([key, value]) => {
                return (
                  <div key={key} className={styles.infoEntry}>
                    <span className={styles.infoKey}>{key}</span>
                    <span className={styles.infoValue}>{value}</span>
                  </div>
                );
              })}
          </div>
        );
      },
    },
    {
      key: 'iconLine',
      renderHeader: () => '',
      renderCell: (
        iconsLine: {
          icons: any[];
          alerting?: boolean;
          progress?: number;
        },
        headerCombined: { header: string }
      ) => (
        <div className={styles.iconLine}>
          {iconsLine.alerting && (
            <div className={styles.iconParent}>
              <div
                className={'icon-alert'}
                style={{
                  color: '#EC3D3D',
                  fontSize: 19,
                }}
              />
            </div>
          )}
          {iconsLine.progress && (
            <div className={styles.progress}>
              <span className={styles.progressNumber}>
                {iconsLine.progress} %
              </span>
              <div
                className={classNames(
                  styles.progressIcon,
                  'icon-refresh',
                  'icon-spin'
                )}
              />
            </div>
          )}
          {iconsLine.icons &&
            iconsLine.icons.map((icon, index) => {
              if (!icon.linkTo) {
                return (
                  <div
                    key={index}
                    className={styles.iconParent}
                    data-tooltip-id={
                      icon.tooltip
                        ? `tooltip-${headerCombined.header}`
                        : undefined
                    }
                    onClick={(e) => icon.onClick && icon.onClick(e)}
                  >
                    <icon.Icon />

                    {icon.tooltip && (
                      <DefaultTooltip id={`tooltip-${headerCombined.header}`}>
                        {intl.formatMessage(icon.tooltip)}
                      </DefaultTooltip>
                    )}
                  </div>
                );
              } else {
                return (
                  <Link key={index} to={icon.linkTo} className={styles.link}>
                    <div
                      className={styles.iconParent}
                      data-tooltip-id={
                        icon.tooltip
                          ? `tooltip-${headerCombined.header}`
                          : undefined
                      }
                      onClick={(e) => icon.onClick && icon.onClick(e)}
                    >
                      <icon.Icon />

                      {icon.tooltip && (
                        <DefaultTooltip id={`tooltip-${headerCombined.header}`}>
                          {intl.formatMessage(icon.tooltip)}
                        </DefaultTooltip>
                      )}
                    </div>
                  </Link>
                );
              }
            })}
        </div>
      ),
      width: '0px', // This causes the column to grow exactly as big as its content
    },
  ];

  const data = elements
    .map((element) => {
      if (element.type === 'card') {
        return {
          ...element,
          content: {
            error: element.error,
            info: element.info,
          },
          headerCombined: {
            header: element.header,
            barColor: element.barColor,
            alerting: element.alerting,
            greyedOut: element.greyedOut,
            type: 'card',
          },
          iconLine: {
            icons: element.icons,
            alerting: element.alerting,
            progress: element.progress,
          },
        };
      } else return undefined;
    })
    .filter((element) => !!element);

  const alertingElements = {};
  elements.forEach((element, rowIndex) => {
    if (element.type === 'card') {
      alertingElements[rowIndex] = element.alerting;
    }
  });

  const greyedOutElements = {};
  elements.forEach((element, rowIndex) => {
    if (element.type === 'card') {
      greyedOutElements[rowIndex] = element.greyedOut;
    }
  });

  const rowLinks = {};
  elements.forEach((element, rowIndex) => {
    if (element.type === 'card') {
      rowLinks[rowIndex] = element.linkTo;
    }
  });

  const renderInner = () => {
    return (
      <div className={styles.elementCardTable}>
        <Table
          data={data}
          // @ts-ignore
          renderColumns={renderColumns}
          showHeader={false}
          striped
          roundedBorder
          borderAround
          verticalAlignMiddle
          addlTableClassName={styles.table}
          addlRowClassname={(index: number) =>
            classNames(
              styles.elementCardTableRow,
              { [styles.alerting]: alertingElements[index] },
              { [styles.greyedOut]: greyedOutElements[index] }
            )
          }
          rowLinkTo={(index: number) => rowLinks[index]}
        />
      </div>
    );
  };

  if (!elements) {
    return null;
  } else {
    return renderInner();
  }
};

ElementCardTable.defaultProps = {};

// @ts-ignore
export default injectIntl(ElementCardTable);
