import { UseQueryResult } from '@tanstack/react-query';
import {
  RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
} from 'common/dist/constants/keycloak';
import auditLogMsgs from 'common/dist/messages/auditLog';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import { FrontendConfig } from 'common/dist/types/frontendConfig';
import { ModuleAvatar } from 'common/dist/types/module';
import {
  REPO_TYPE,
  repoTypeToSpeakingPlural,
} from 'common/dist/types/repository';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';
import React, { useEffect } from 'react';
import {
  FiActivity,
  FiBarChart2,
  FiClock,
  FiDatabase,
  FiEdit,
  FiGitBranch,
  FiGrid,
  FiLayers,
  FiLayout,
  FiList,
  FiMonitor,
  FiPlay,
  FiRepeat,
  FiServer,
  FiSettings,
  FiSliders,
  FiTable,
  FiTerminal,
  FiTrendingUp,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { SiOpentelemetry } from 'react-icons/si';
import { VscVscodeInsiders } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import ChatIcon from './ChatIcon';
import {
  MenuEntryType,
  MenuHeadlineType,
  MenuHrType,
  SubMenuEntriesType,
} from './MenuEntries';
import SwaggerIcon from './SwaggerIcon';
import { useModuleFiles } from '../../../core/api/modules';
import { useRepoDetails } from '../../../core/api/workbench/collab';
import { useWorkbenchProjects } from '../../../core/api/workbench/git.notebook';
import { useRepositoriesByRepoFullName } from '../../../core/api/workbench/repositories';
import {
  getActiveProjectPath,
  setActiveProjectPath,
} from '../../../store/workbench/activeProject.slice';
import { ProjectInfo } from '../../../store/workbench/state.types';
import { workbenchRoutes } from '../../../workbench/common/workbenchRoutes';
import { resourceRoutes } from '../../admin/resources/routes';
import { usersRoutes } from '../../admin/users/routes';
import { appsRoutes } from '../../apps/routes';
import {
  IconApp,
  IconCodeCapsule,
  IconModule,
  IconPlainRepository,
} from '../../atoms/altasigma-icons/AltaSigmaIcons';
import { collaborationSpaceRoutes } from '../../collaborationSpace/routes';
import { dataManagementRoutes } from '../../dataManagement/routes';
import * as routes from '../../index/routes';
import { orchestrationRoutes } from '../../orchestration/routes';

const ICON_SIZE = 16;

function useDeriveWorkbenchEntry(
  permissions: Record<string, string[]>,
  isWorkbenchRunning: boolean,
  isVsCodeAvailable: boolean
): MenuEntryType | null {
  const showWorkbench = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX
  );
  const dispatch = useDispatch();
  const activeProjectPath: string = useSelector(getActiveProjectPath);

  const { data: projects, isSuccess: isProjectsSuccess } =
    useWorkbenchProjects(isWorkbenchRunning);

  const { data: repositories, isSuccess: isRepositoriesSuccess } =
    useRepositoriesByRepoFullName(
      projects?.map((project) => project.repoFullName) || [],
      isProjectsSuccess
    );

  // Filter projects with existing repositories
  const projectsWithRepositories = (() => {
    if (!projects || !repositories) return [];

    return projects.filter((project) =>
      repositories.some((repo) => repo.repoFullName === project.repoFullName)
    );
  })();

  const repoMeta = Object.values(
    isProjectsSuccess && isRepositoriesSuccess ? projectsWithRepositories : {}
  )
    .flat<ProjectInfo[]>()
    .find((project) => project.path === activeProjectPath);
  useEffect(() => {
    if (isProjectsSuccess && isRepositoriesSuccess)
      if (!repoMeta && activeProjectPath) {
        //Clear the active project if nothing is selected
        dispatch(setActiveProjectPath(''));
      }
  }, [
    repoMeta,
    activeProjectPath,
    isProjectsSuccess,
    isRepositoriesSuccess,
    dispatch,
  ]);

  const { data: moduleFiles } = useModuleFiles(
    activeProjectPath,
    repoMeta?.repoType === REPO_TYPE.MODULE
  );

  const [repoGroup, repoName] = repoMeta?.repoFullName?.split('/') || [];
  const { data: activeRepoDetails, isInitialLoading } = useRepoDetails(
    repoGroup,
    repoName,
    !!repoMeta
  );

  if (!showWorkbench) return null;

  const subMenuEntries: SubMenuEntriesType[] = [];

  if (isWorkbenchRunning) {
    let name: string, avatar: ModuleAvatar;
    if (activeRepoDetails) {
      switch (activeRepoDetails.repoType) {
        case 'module':
          name = activeRepoDetails.module.name;
          avatar = activeRepoDetails.module.moduleAvatar;
          break;
        case 'code-capsule':
          name = activeRepoDetails.codeCapsule.name;
          break;
        case 'app':
          name = activeRepoDetails.app.name;
          break;
        case 'plain':
          name = activeRepoDetails.name;
      }
    }

    subMenuEntries.push({
      type: 'projectLink',
      id: 'active-project',
      path: `${workbenchRoutes.basePath}${workbenchRoutes.projectsOverview}`,
      title: {
        id: 'activeProject',
        defaultMessage: name ? name : 'No active project',
      },
      repoType: repoMeta?.repoType,
      avatar: avatar,
      isLoading: isInitialLoading,
      isActiveProject: !!repoMeta,
    });
    subMenuEntries.push({
      type: 'entry',
      id: 'notebook-environment',
      path: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`,
      title: {
        id: 'todo',
        defaultMessage: 'Notebook Environment',
      },
      Icon: FiEdit,
    });

    if (isVsCodeAvailable) {
      subMenuEntries.push({
        type: 'entry',
        id: 'vscode',
        path: `${workbenchRoutes.basePath}${workbenchRoutes.vsCode}`,
        title: {
          id: 'todo',
          defaultMessage: 'VS Code',
        },
        Icon: VscVscodeInsiders,
      });
    }

    subMenuEntries.push({
      type: 'entry',
      id: 'workbench-resource-management',
      path: `${workbenchRoutes.basePath}${workbenchRoutes.resourceManagement}`,
      title: {
        id: 'todo',
        defaultMessage: 'Resource Management',
      },
      Icon: FiActivity,
    });

    // If inside a repository
    if (repoMeta) {
      // if repoMeta exists, we are inside a repository
      subMenuEntries.push({
        type: 'entry',
        id: 'wb-version-control',
        path: `${workbenchRoutes.basePath}${workbenchRoutes.versionControl}`,
        title: {
          id: 'todo',
          defaultMessage: 'Version Control',
        },
        Icon: FiGitBranch,
      });

      if (['module'].includes(repoMeta?.repoType)) {
        // Inside a module repository
        subMenuEntries.push({
          type: 'entry',
          id: 'wb-general-config',
          path: `${workbenchRoutes.basePath}${workbenchRoutes.generalConfig}`,
          title: {
            id: 'todo',
            defaultMessage: 'General Configuration',
          },
          Icon: FiSettings,
        });

        subMenuEntries.push({
          type: 'entry',
          id: 'wb-dev-augur',
          path: `${workbenchRoutes.basePath}${workbenchRoutes.devAugur}`,
          title: {
            id: 'todo',
            defaultMessage: 'Dev Augur', // TODO: Maybe "Workbench Augur" fits better?
          },
          Icon: FiLayout,
        });

        subMenuEntries.push({
          type: 'entry',
          id: 'wb-terminal',
          path: `${workbenchRoutes.basePath}${workbenchRoutes.terminal}`,
          title: {
            id: 'todo',
            defaultMessage: 'Execute',
          },
          Icon: FiPlay,
        });

        if (
          moduleFiles?.config?.generalConfiguration?.supportsRealtimePrediction
        ) {
          // "generalConfiguration?" should not need to be checked, since it should always be there if the config is there, but it's safer since this component should never crash

          subMenuEntries.push({
            type: 'entry',
            id: 'wb-swagger',
            path: `${workbenchRoutes.basePath}${workbenchRoutes.swagger}`,
            title: {
              id: 'todo',
              defaultMessage: 'Swagger',
            },
            Icon: SwaggerIcon,
          });
        }
      }
    }
  }

  return {
    id: 'workbench',
    type: 'entry',
    path: workbenchRoutes.basePath,
    title: {
      id: 'dashboard.tab.workbench',
      defaultMessage: 'Workbench',
    },
    Icon: () => <FiEdit size={ICON_SIZE} />,
    subMenuEntries,
  };
}

function useDeriveCollaborationSpaceEntry(
  permissions: Record<string, string[]>
): MenuEntryType | null {
  const showCollaborationSpace = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX
  );
  if (!showCollaborationSpace) return null;
  const subMenuEntries: SubMenuEntriesType[] = [
    {
      type: 'entry',
      id: 'modules',
      path: `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewModules}`,
      title: {
        id: 'todo',
        defaultMessage: repoTypeToSpeakingPlural['module'],
      },
      Icon: IconModule,
    },
    {
      type: 'entry',
      id: 'code-capsules',
      path: `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewCodeCapsules}`,
      title: {
        id: 'todo',
        defaultMessage: repoTypeToSpeakingPlural['code-capsule'],
      },
      Icon: IconCodeCapsule,
    },
    {
      type: 'entry',
      id: 'apps',
      path: `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewApps}`,
      title: {
        id: 'todo',
        defaultMessage: repoTypeToSpeakingPlural['app'],
      },
      Icon: IconApp,
    },
    {
      id: 'repositories',
      type: 'entry',
      path: `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.overviewPlain}`,
      title: {
        id: 'dashboard.tab.collab.repositories',
        defaultMessage: repoTypeToSpeakingPlural['plain'],
      },
      Icon: IconPlainRepository,
    },
  ];

  return {
    id: 'collab',
    type: 'entry',
    path: collaborationSpaceRoutes.basePath,
    title: {
      id: 'dashboard.tab.collaboration_space',
      defaultMessage: 'Collaboration Space',
    },
    Icon: () => <FiGitBranch size={ICON_SIZE} />,
    subMenuEntries,
  };
}

function useDeriveOrchestrationEntry(
  permissions: Record<string, string[]>,
  isAdmin: boolean
): MenuEntryType | null {
  const showOrchestration = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX
  );
  if (!showOrchestration) return null;

  const subMenuEntries: SubMenuEntriesType[] = [
    {
      id: 'queue',
      type: 'entry',
      path: `${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}`,
      title: {
        id: 'dashboard.tab.orchestration.job_queue',
        defaultMessage: 'Job Queue',
      },
      Icon: FiLayers,
    },
    {
      id: 'history',
      type: 'entry',
      path: `${orchestrationRoutes.basePath}/${orchestrationRoutes.history.path}`,
      title: {
        id: 'dashboard.tab.orchestration.job_history',
        defaultMessage: 'Job History',
      },
      Icon: FiClock,
    },
    {
      id: 'job_groups',
      type: 'entry',
      path: `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroups.path}`,
      title: {
        id: 'dashboard.tab.orchestration.job_groups',
        defaultMessage: 'Job Groups',
      },
      Icon: FiGrid,
    },
    {
      id: 'job_schedules',
      type: 'entry',
      path: `${orchestrationRoutes.basePath}/${orchestrationRoutes.schedulings.path}`,
      title: {
        id: 'dashboard.tab.orchestration.job_schedules',
        defaultMessage: 'Job Schedules',
      },
      Icon: FiRepeat,
    },
    {
      id: 'realtime',
      type: 'entry',
      path: `${orchestrationRoutes.basePath}/${orchestrationRoutes.realtime.path}`,
      title: {
        id: 'dashboard.tab.orchestration.realtime',
        defaultMessage: 'Realtime',
      },
      Icon: FiSliders,
    },
  ];

  if (isAdmin) {
    subMenuEntries.push({
      id: 'jobconfiguration',
      type: 'entry',
      path: `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobConfiguration.path}`,
      title: {
        id: 'dashboard.tab.orchestration.jobconfigs',
        defaultMessage: 'Job Configuration',
      },
      Icon: FiActivity,
    });
  }

  return {
    id: 'orchestration',
    type: 'entry',
    path: '/app/orchestration',
    title: {
      id: 'dashboard.tab.orchestration',
      defaultMessage: 'Orchestration',
    },
    Icon: () => <FiLayers size={ICON_SIZE} />,
    subMenuEntries,
  };
}

function useDeriveDataManagementEntry(
  permissions: Record<string, string[]>,
  isAdmin: boolean,
  qrDataSources: UseQueryResult<DataSource[]>
): MenuEntryType | null {
  const showDataMan = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX
  );
  if (!showDataMan) return null;

  const subMenuEntries: SubMenuEntriesType[] = [];

  if (qrDataSources.isLoading) {
    subMenuEntries.push({ type: 'loading' });
  } else if (qrDataSources.isSuccess) {
    qrDataSources.data
      .sort((a, b) => {
        // Sorting rule 1: S3 before Cassandra
        if (a.ds_type === 's3' && b.ds_type !== 's3') {
          return -1;
        }
        if (a.ds_type !== 's3' && b.ds_type === 's3') {
          return 1;
        }

        // Rule 2: Sort by name
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((ds) =>
        subMenuEntries.push({
          id: ds.code,
          type: 'entry',
          path: `${dataManagementRoutes.basePath}/${ds.ds_type}/${ds.code}`,
          title: {
            id: 'no-id',
            defaultMessage: ds.name,
          },
          Icon: () => <FiDatabase size={ICON_SIZE} />,
        })
      );
  }

  // Manage
  if (isAdmin) {
    subMenuEntries.push({
      id: 'ds-manage',
      type: 'entry',
      path: `${dataManagementRoutes.basePath}/manage`,
      title: {
        id: 'no-id',
        defaultMessage: 'Manage',
      },
      Icon: () => <FiSliders size={ICON_SIZE} />,
    });
  }

  return {
    id: 'data',
    type: 'entry',
    path: dataManagementRoutes.basePath,
    title: {
      id: 'dashboard.tab.data_management',
      defaultMessage: 'Data Management',
    },
    Icon: () => <FiDatabase size={ICON_SIZE} />,
    subMenuEntries,
  };
}

/**
 * Self-Service-Analytics and AI Pilot (if available)
 */
const useDeriveExternalToolsEntries = (
  frontendConfig: FrontendConfig
): (MenuEntryType | MenuHrType | MenuHeadlineType)[] => {
  let entries: (MenuEntryType | MenuHrType | MenuHeadlineType)[] = [];

  // Superset
  if (frontendConfig?.components?.superset?.enabled) {
    const superset = frontendConfig?.components?.superset;
    entries.push({
      id: 'self-service-analytics',
      type: 'entry',
      path: supersetLink(superset.scheme, superset.domain),
      title: {
        id: 'todo',
        defaultMessage: 'Self Service Analytics',
      },
      Icon: FiBarChart2,
      openInNewTab: true,
    });
  }

  // AI Pilot
  if (frontendConfig?.components?.aiPilot?.enabled) {
    const aiPilot = frontendConfig?.components?.aiPilot;
    entries.push({
      id: 'ai-pilot',
      type: 'entry',
      path: aiPilotLink(aiPilot.scheme, aiPilot.subpath),
      title: {
        id: 'todo',
        defaultMessage: 'AI Pilot',
      },
      Icon: ChatIcon,
      openInNewTab: true,
    });
  }

  // Append a HR If necessary
  if (entries.length > 0) {
    entries = [
      {
        type: 'hr',
      },
      ...entries,
    ];
  }

  return entries;
};

const supersetLink = (scheme: string, domain: string) =>
  `${scheme}://${domain}/login/keycloak`;

const aiPilotLink = (scheme: string, subpath: string) =>
  `${scheme}://${window.location.host}/${subpath}/`;

const useDeriveAdminEntries = (
  frontendConfig: FrontendConfig
): (MenuEntryType | MenuHrType | MenuHeadlineType)[] => {
  const entries: (MenuEntryType | MenuHrType | MenuHeadlineType)[] = [];

  // HR
  entries.push({
    type: 'hr',
  });

  // Headline
  entries.push({
    type: 'headline',
    headline: {
      id: 'todo',
      defaultMessage: 'ADMIN',
    },
  });

  entries.push({
    id: 'users-and-permissions',
    type: 'entry',
    path: `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}`,
    title: {
      id: 'header.menu.adminUsersList',
      defaultMessage: 'Users & Permissions',
    },
    Icon: () => <FiUsers size={ICON_SIZE} />,
    subMenuEntries: [
      {
        type: 'entry',
        id: 'list',
        path: `${usersRoutes.basePath}/${usersRoutes.userList.path}`,
        title: {
          id: 'dashboard.tab.users.userlist',
          defaultMessage: 'Users',
        },
        Icon: () => <FiUser size={ICON_SIZE} />,
      },
      {
        type: 'entry',
        id: 'groups',
        path: `${usersRoutes.basePath}/${usersRoutes.groups.path}`,
        title: {
          id: 'dashboard.tab.users.groups',
          defaultMessage: 'Groups',
        },
        Icon: () => <FiUsers size={ICON_SIZE} />,
      },
      {
        type: 'entry',
        id: 'habitats',
        path: `${usersRoutes.basePath}/${usersRoutes.habitats.path}`,
        title: {
          id: 'todo',
          defaultMessage: 'Habitats',
        },
        // TODO this does not look quite right yet: hover while selected
        Icon: () => (
          <span
            // The habitat icon is a bit smaller than the ReactFi-Icons. The font size is specified explicitly here to compensate for that size difference.
            style={{ fontSize: '18px' }}
            className={'icon-home'}
          />
        ),
      },
      { type: 'hr' },
      {
        type: 'entry',
        id: 'generalPermissions',
        path: `${usersRoutes.basePath}/${usersRoutes.domainPermissions.path}`,
        title: {
          id: 'dashboard.tab.users.domain_permissions',
          defaultMessage: 'Domain Permissions',
        },
        Icon: () => <FiTable size={ICON_SIZE} />,
      },
      {
        type: 'entry',
        id: 'appPermissions',
        path: `${usersRoutes.basePath}/${usersRoutes.appPermissions.path}`,
        title: {
          id: 'dashboard.tab.users.app_permissions',
          defaultMessage: 'App Permissions',
        },
        Icon: () => <FiMonitor size={ICON_SIZE} />,
      },
      {
        type: 'entry',
        id: 'dataSourcePermissions',
        path: `${usersRoutes.basePath}/${usersRoutes.dataSourcePermissions.path}`,
        title: {
          id: 'todo',
          defaultMessage: 'Data Source Permissions',
        },
        Icon: () => <FiDatabase size={ICON_SIZE} />,
      },
    ],
  });

  entries.push({
    id: 'resources',
    type: 'entry',
    path: `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.resources}`,
    title: {
      id: 'header.menu.adminResources',
      defaultMessage: 'Resources',
    },
    Icon: () => <FiServer size={ICON_SIZE} />,
    subMenuEntries: [
      {
        id: 'runningWorkbench',
        type: 'entry',
        path: `${resourceRoutes.basePath}/${resourceRoutes.runningWorkbench.path}`,
        title: {
          id: 'dashboard.tab.resources.running_workbench',
          defaultMessage: 'Running Workbenches',
        },
        Icon: () => <FiRepeat size={ICON_SIZE} />,
      },
      {
        id: 'runningApps',
        type: 'entry',
        path: `${resourceRoutes.basePath}/${resourceRoutes.runningApps.path}`,
        title: {
          id: 'dashboard.tab.resources.running_apps',
          defaultMessage: 'Running Apps',
        },
        Icon: () => <FiRepeat size={ICON_SIZE} />,
      },
    ],
  });

  /* TODO: Enable again once the maintenance and backup features are tested properly.
  entries.push({
    id: 'maintenance',
    type: 'entry',
    path: `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.maintenance}`,
    title: {
      id: 'todo',
      defaultMessage: 'Maintenance',
    },
    Icon: () => <FiTool size={ICON_SIZE} />,
  });
   */

  entries.push({
    id: 'audit-log',
    type: 'entry',
    path: `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.auditLog}`,
    title: auditLogMsgs.msgAuditLog,
    Icon: () => <FiList size={ICON_SIZE} />,
  });

  entries.push({
    type: 'hr',
  });

  if (frontendConfig?.components?.grafana?.enabled) {
    entries.push({
      id: 'metrics',
      type: 'entry',
      path: `/${frontendConfig?.components?.grafana?.subpath}`,
      title: {
        id: 'header.menu.adminMetrics',
        defaultMessage: 'Metrics',
      },
      Icon: () => <FiTrendingUp size={ICON_SIZE} />,
      openInNewTab: true,
    });
  }

  if (frontendConfig?.components?.opensearch?.enabled) {
    entries.push({
      id: 'logging',
      type: 'entry',
      path: `/${frontendConfig?.components?.opensearch?.subpath}`,
      title: {
        id: 'header.menu.adminLogging',
        defaultMessage: 'Logging',
      },
      Icon: () => <FiTerminal size={ICON_SIZE} />,
      openInNewTab: true,
    });
  }

  if (frontendConfig?.components?.observability?.enabled) {
    entries.push({
      id: 'observability',
      type: 'entry',
      path: `${frontendConfig?.components?.observability?.scheme}://${frontendConfig?.components?.observability?.domain}/altasigma`,
      title: {
        id: 'header.menu.adminObservability',
        defaultMessage: 'Observability',
      },
      Icon: () => <SiOpentelemetry size={ICON_SIZE} />,
      openInNewTab: true,
    });
  }

  return entries;
};

export function useDeriveMenuEntries(args: {
  permissions: Record<string, string[]>;
  isAdmin: boolean;
  qrDataSources: UseQueryResult<DataSource[]>;
  frontendConfig: FrontendConfig;
  isWorkbenchRunning: boolean;
  isVsCodeAvailable: boolean;
}): (MenuEntryType | MenuHrType | MenuHeadlineType)[] {
  const {
    permissions,
    isAdmin,
    qrDataSources,
    frontendConfig,
    isWorkbenchRunning,
    isVsCodeAvailable,
  } = args;
  let menuEntries: (MenuEntryType | MenuHrType | MenuHeadlineType)[] = [];

  const showModelManagement = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX
  );
  const showAiApps = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX
  );

  // --- Model Management
  if (showModelManagement) {
    menuEntries.push({
      id: 'models',
      type: 'entry',
      path: '/app/models',
      title: {
        id: 'dashboard.tab.modelManagement',
        defaultMessage: 'Model Management',
      },
      Icon: () => <FiGrid size={ICON_SIZE} />,
    });
  }

  // --- AI Apps
  if (showAiApps) {
    menuEntries.push({
      id: 'apps',
      type: 'entry',
      path: appsRoutes.basePath,
      title: {
        id: 'dashboard.tab.apps',
        defaultMessage: 'AI Apps',
      },
      Icon: () => <FiMonitor size={ICON_SIZE} />,
    });
  }

  // --- Workbench
  const optWorkbenchEntry = useDeriveWorkbenchEntry(
    permissions,
    isWorkbenchRunning,
    isVsCodeAvailable
  );
  if (optWorkbenchEntry) {
    menuEntries.push(optWorkbenchEntry);
  }

  // --- Collaboration Space
  const optCollaborationSpaceEntry =
    useDeriveCollaborationSpaceEntry(permissions);
  if (optCollaborationSpaceEntry) {
    menuEntries.push(optCollaborationSpaceEntry);
  }

  // --- Orchestration
  const optOrchestrationEntry = useDeriveOrchestrationEntry(
    permissions,
    isAdmin
  );
  if (optOrchestrationEntry) {
    menuEntries.push(optOrchestrationEntry);
  }

  // --- Data Management
  const optDataManEntry = useDeriveDataManagementEntry(
    permissions,
    isAdmin,
    qrDataSources
  ); // TODO Data sources!
  if (optDataManEntry) {
    menuEntries.push(optDataManEntry);
  }

  // --- Superset and AI Pilot
  const externalToolsEntries = useDeriveExternalToolsEntries(frontendConfig);
  menuEntries = menuEntries.concat(externalToolsEntries);

  // -- Admin entries
  const adminEntries = useDeriveAdminEntries(frontendConfig);
  if (isAdmin) {
    menuEntries = menuEntries.concat(adminEntries);
  }

  return menuEntries;
}
